import {
	Heading,
	Text,
	SimpleGrid,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Skeleton,
	Flex,
	Card,
	CardHeader,
	Link as ChakraLink,
	CardBody,
	Badge,
	CardFooter,
	Button,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaChevronRight, FaExternalLinkSquareAlt } from "react-icons/fa";
import styled from "styled-components";
import { MenuBar, PageContainer } from "../components/atoms";
import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { User } from "../types";

const BUSINESSES_QUERY = gql`
	query BusinessesQuery {
		selfEarnyStaffAdminGetBusinesses {
			id
			name
			numJobListings
		}
	}
`;

interface ViewBusinesses {
	id: string;
	name: string;
	numJobListings: number;
}

export const ViewBusinesses = () => {
	const { loading, data } = useQuery(BUSINESSES_QUERY);

	useEffect(() => {
		setBusinesses(data?.selfEarnyStaffAdminGetBusinesses);
	}, [data]);

	const [businesses, setBusinesses] = useState<ViewBusinesses[]>();
	const navigate = useNavigate();

	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>View businesses</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<SpacingContainer>
					<Heading>Businesses</Heading>
				</SpacingContainer>

				<Divider />

				<SpacingContainer>
					<Heading size="md">Applicant details</Heading>
				</SpacingContainer>

				{loading && (
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						<Skeleton height={200} />
						<Skeleton height={200} />
						<Skeleton height={200} />
					</SimpleGrid>
				)}
				<SimpleGrid columns={[1, 1, 3]} spacing={10}>
					{businesses?.map((business: ViewBusinesses) => (
						<Card key={business.id}>
							<CardHeader>
								<Heading size="md">{business.name}</Heading>
							</CardHeader>
							<CardBody>
								<Text>{business.numJobListings} job listings</Text>
							</CardBody>
							<CardFooter>
								<Button
									onClick={() =>
										navigate(`/view-businesses/view`, {
											state: {
												id: business.id,
											},
										})
									}
								>
									<FaChevronRight />
								</Button>
							</CardFooter>
						</Card>
					))}
				</SimpleGrid>
			</Container>
		</PageContainer>
	);
};

const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;

const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;
