import {
	Heading,
	Button,
	FormControl,
	FormLabel,
	Input,
	Container,
	Text,
	Stack,
	InputGroup,
	InputLeftElement,
	Icon,
	Box,
	Divider,
	useToast,
	Image,
} from "@chakra-ui/react";
import { useContext, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { AuthContextType } from "../../contexts/AuthContextType";
import { FaRegEnvelope } from "react-icons/fa";

export const ResetPassword = () => {
	const emailRef = useRef<HTMLInputElement>(null);
	const [loading, setLoading] = useState(false);

	const { resetPassword } = useContext<AuthContextType>(AuthContext);
	const navigate = useNavigate();
	const toast = useToast();

	async function handleSubmit() {
		try {
			setLoading(true);
			await resetPassword(emailRef.current!.value);
		} catch (error) {}

		navigate("/signin");
		toast({
			title: "Password reset email sent.",
			status: "success",
			isClosable: true,
		});
		setLoading(false);
	}

	return (
		<Container
			className="d-flex align-items-center justify-content-center"
			style={{ minHeight: "100vh" }}
		>
			<Box
				width={{ base: "90%", md: "400px" }}
				bg="secondary.card"
				rounded="lg"
				p={5}
			>
				<Box pb="2">
					<Image src="logo.png" height="40px" width="auto" />
				</Box>

				<Heading marginBottom="1.5rem">Reset password</Heading>

				<Stack spacing={4} marginBottom="1rem">
					<FormControl>
						<FormLabel htmlFor="email">Email Address</FormLabel>
						<InputGroup>
							<InputLeftElement
								children={
									<Icon as={FaRegEnvelope} color="secondary.inputHelper" />
								}
							/>
							<Input
								focusBorderColor="purple.500"
								type="email"
								name="email"
								id="email"
								ref={emailRef}
								placeholder="name@example.com"
							/>
						</InputGroup>
					</FormControl>
				</Stack>
				<Stack marginBottom="1rem">
					<Button
						type="submit"
						colorScheme="purple"
						isLoading={loading}
						onClick={handleSubmit}
					>
						Reset password
					</Button>
				</Stack>

				<Divider marginBottom="1rem" />
				<Stack>
					<Text textAlign="center" fontWeight="500">
						Already have an account?
						<ChakraLink
							color="purple.500"
							variant="outline"
							as={ReactRouterLink}
							to="/signin"
							pl="2"
						>
							Sign in
						</ChakraLink>
					</Text>
				</Stack>
			</Box>
		</Container>
	);
};
