import { useEffect, useState } from "react";
import {
	Heading,
	Text,
	Card,
	SimpleGrid,
	CardHeader,
	CardBody,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Badge,
	Skeleton,
	Textarea,
	Checkbox,
	Select,
	CardFooter,
	Button,
	Flex,
	useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MenuBar, PageContainer } from "../components/atoms";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ACCOUNT_SETUP_STATUS, User } from "../types";
import { FaChevronRight } from "react-icons/fa";

const APP_USERS_QUERY = gql`
	query SelfEarnyStaffAdminAppUsers {
		selfEarnyStaffAdminAppUsers {
			fullName
			uid
			accountSetupStatus
			phone {
				areaCode
				phoneNumber
			}
		}
	}
`;

const SEND_SMS_MUTATION = gql`
	mutation SendSms($users: [UserUidInput!]!, $message: String!) {
		earnyStaffAdminSendSms(users: $users, message: $message)
	}
`;

export const ManageSms = () => {
	const { loading, data } = useQuery(APP_USERS_QUERY);
	const [appUsers, setAppUsers] = useState<User[] | undefined>();
	const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
	const [filterStatus, setFilterStatus] = useState<
		ACCOUNT_SETUP_STATUS | undefined
	>(undefined);
	const [isAllSelected, setIsAllSelected] = useState(false);
	const [sendSms, { loading: sending }] = useMutation(SEND_SMS_MUTATION);
	const toast = useToast();

	useEffect(() => {
		setAppUsers(data?.selfEarnyStaffAdminAppUsers);
	}, [data]);

	const handleSelectUser = (user: User) => {
		setSelectedUsers((prevSelected) => {
			const newSelected = prevSelected.includes(user)
				? prevSelected.filter((u) => u !== user)
				: [...prevSelected, user];

			if (newSelected.length !== filteredUsers?.length) {
				setIsAllSelected(false);
			} else {
				setIsAllSelected(true);
			}

			return newSelected;
		});
	};

	const handleSelectAll = () => {
		if (isAllSelected) {
			setSelectedUsers([]);
		} else {
			setSelectedUsers(filteredUsers || []);
		}
		setIsAllSelected(!isAllSelected);
	};

	const handleSendMessage = async () => {
		const message = document.querySelector("textarea")?.value;

		try {
			const { data } = await sendSms({
				variables: {
					users: selectedUsers.map((u) => ({
						uid: u.uid,
					})),
					message,
				},
			});

			if (data?.earnyStaffAdminSendSms) {
				toast({
					title: "Success",
					description: "Message sent successfully!",
					status: "success",
					duration: 5000,
					isClosable: true,
					position: "bottom-right",
				});
			} else {
				toast({
					title: "Failed",
					description: `Failed to send message. Please try again.`,
					status: "error",
					duration: 5000,
					isClosable: true,
					position: "bottom-right",
				});
			}
		} catch (error) {
			console.error("Error sending message:", error);
			toast({
				title: "Error",
				description: "An error occurred while sending the message.",
				status: "error",
				duration: 5000,
				isClosable: true,
				position: "bottom-right",
			});
		}
	};

	const handleInsertFullName = () => {
		const textareaElement = document.querySelector("textarea");
		if (textareaElement) {
			textareaElement.value += " {{fullName}}";
		}
	};

	const filteredUsers = appUsers?.filter((user) =>
		filterStatus ? user.accountSetupStatus === filterStatus : true
	);

	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem as={Link} to="/view-users">
						<BreadcrumbLink>View users</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>Manage SMS</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<SpacingContainer>
					<Heading>Manage SMS</Heading>
				</SpacingContainer>

				<Divider />

				<Flex pb={2}>
					<Button onClick={handleInsertFullName} size="sm">
						Add fullName
					</Button>
				</Flex>
				<Flex gap={1}>
					<Textarea placeholder="SMS message" />
					<Button onClick={handleSendMessage} isLoading={sending}>
						Send SMS
					</Button>
				</Flex>

				<Divider />

				<SpacingContainer>
					<Select
						placeholder="Filter by Account Setup Status"
						onChange={(e) =>
							setFilterStatus(e.target.value as ACCOUNT_SETUP_STATUS)
						}
					>
						<option value={ACCOUNT_SETUP_STATUS.RECORD_VIDEO}>
							Record Video
						</option>
						<option value={ACCOUNT_SETUP_STATUS.JOB_MATCHING}>
							Job Matching
						</option>
						<option value={ACCOUNT_SETUP_STATUS.MATCHED}>Matched</option>
						<option value={ACCOUNT_SETUP_STATUS.INTERVIEWING}>
							Interviewing
						</option>
						<option value={ACCOUNT_SETUP_STATUS.COMPLETE}>Complete</option>
					</Select>
				</SpacingContainer>

				<SpacingContainer>
					<Button onClick={handleSelectAll} mb={4}>
						{isAllSelected ? "Deselect All" : "Select All"}
					</Button>
				</SpacingContainer>

				<SpacingContainer>
					<Heading size="lg">Users by newness</Heading>
				</SpacingContainer>
				<SpacingContainer>
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						{loading ? (
							<>
								<CardSkeleton />
								<CardSkeleton />
								<CardSkeleton />
							</>
						) : (
							filteredUsers?.map((user, i) => {
								const { fullName, accountSetupStatus, phone } = user;
								return (
									<Card key={i}>
										<CardHeader display="flex" justifyContent="space-between">
											<Checkbox
												isChecked={selectedUsers.includes(user)}
												onChange={() => handleSelectUser(user)}
											>
												<Heading size="md">{fullName}</Heading>
											</Checkbox>
										</CardHeader>
										<CardBody>
											<Text>{`Phone Number: +${phone.areaCode}${phone.phoneNumber}`}</Text>
											<Badge
												colorScheme={accountSetupStatusToBadgeColor(
													accountSetupStatus
												)}
											>
												{accountSetupStatus?.replaceAll("_", " ")}
											</Badge>
										</CardBody>
									</Card>
								);
							})
						)}
					</SimpleGrid>
				</SpacingContainer>
			</Container>
		</PageContainer>
	);
};

const accountSetupStatusToBadgeColor = (status: ACCOUNT_SETUP_STATUS) => {
	switch (status) {
		case "RECORD_VIDEO":
			return "yellow";
		case "JOB_MATCHING":
			return "green";
		default:
			return "gray";
	}
};

const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;

const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;

const CardSkeleton = () => (
	<Skeleton>
		<Card>
			<CardHeader>
				<Heading />
			</CardHeader>
			<CardBody />
			<CardFooter>
				<Button>
					<FaChevronRight />
				</Button>
			</CardFooter>
		</Card>
	</Skeleton>
);
