import { useState, useEffect } from "react";
import {
	User,
	createUserWithEmailAndPassword,
	getAuth,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	confirmPasswordReset,
	checkActionCode,
} from "firebase/auth";
import app from "../firebase";
import { AuthContext } from "../contexts/AuthContext";

export const auth = getAuth(app);

export function AuthProvider({ children }: { children: React.ReactNode }) {
	const [currentUser, setCurrentUser] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);

	function signUp(email: string, password: string) {
		return createUserWithEmailAndPassword(auth, email, password);
	}

	function signIn(email: string, password: string) {
		return signInWithEmailAndPassword(auth, email, password);
	}

	function resetPassword(email: string) {
		return sendPasswordResetEmail(auth, email);
	}

	function newPassword(password: string, resetCode: string) {
		return confirmPasswordReset(auth, resetCode, password);
	}

	function checkNewPasswordCode(code: string) {
		return checkActionCode(auth, code);
	}

	function signOut() {
		return auth.signOut();
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setCurrentUser(user);
			setLoading(false);
		});

		return unsubscribe;
	}, []);

	const value = {
		currentUser,
		signUp,
		signIn,
		signOut,
		resetPassword,
		checkNewPasswordCode,
		newPassword,
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}
