import { useEffect, useState } from "react";
import {
	Heading,
	Text,
	Card,
	SimpleGrid,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Badge,
	Flex,
	Skeleton,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MenuBar, PageContainer } from "../components/atoms";
import { FaBell, FaChevronRight, FaSms } from "react-icons/fa";
import { ACCOUNT_SETUP_STATUS, User } from "../types";
import { gql, useQuery } from "@apollo/client";

const APP_USERS_QUERY = gql`
	query SelfEarnyStaffAdminAppUsers {
		selfEarnyStaffAdminAppUsers {
			fullName
			dateOfBirth
			email
			availableHoursPerWeek
			uid
			accountSetupStatus
			phone {
				areaCode
				phoneNumber
			}
		}
	}
`;

export const ViewUsers = () => {
	const { loading, data } = useQuery(APP_USERS_QUERY);
	const [appUsers, setAppUsers] = useState<User[] | undefined>();

	useEffect(() => {
		setAppUsers(data?.selfEarnyStaffAdminAppUsers);
	}, [data]);

	const navigate = useNavigate();

	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>View users</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<SpacingContainer>
					<Heading>View Users</Heading>
				</SpacingContainer>

				<Divider />
				<Flex gap={1}>
					<Link to="/view-users/notifications">
						<Button leftIcon={<FaBell />}>Manage notifications</Button>
					</Link>

					<Link to="/view-users/sms">
						<Button leftIcon={<FaSms />}>Manage SMS</Button>
					</Link>
				</Flex>
				<SpacingContainer>
					<Heading size="lg">Users by newness</Heading>
				</SpacingContainer>
				<SpacingContainer>
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						{loading ? (
							<>
								<CardSkeleton />
								<CardSkeleton />
								<CardSkeleton />
							</>
						) : (
							appUsers?.map((user, i) => {
								const {
									fullName,
									dateOfBirth,
									email,
									uid,
									accountSetupStatus,
									phone,
								} = user;
								return (
									<Card key={i}>
										<CardHeader display="flex" justifyContent="space-between">
											<Heading size="md">{fullName}</Heading>
										</CardHeader>
										<CardBody>
											<Text>{`Email: ${email}`}</Text>
											<Text>{`Phone Number: +${phone.areaCode}${phone.phoneNumber}`}</Text>
											<Text>{`DOB: ${dateOfBirth}`}</Text>

											<Badge
												colorScheme={accountSetupStatusToBadgeColor(
													accountSetupStatus
												)}
											>
												{accountSetupStatus?.replaceAll("_", " ")}
											</Badge>
										</CardBody>
										<CardFooter>
											<Button
												onClick={() =>
													navigate(`/view-users/view`, {
														state: {
															id: uid,
														},
													})
												}
											>
												<FaChevronRight />
											</Button>
										</CardFooter>
									</Card>
								);
							})
						)}
					</SimpleGrid>
				</SpacingContainer>
			</Container>
		</PageContainer>
	);
};

const accountSetupStatusToBadgeColor = (status: ACCOUNT_SETUP_STATUS) => {
	switch (status) {
		case "RECORD_VIDEO":
			return "yellow";
		case "JOB_MATCHING":
			return "green";
		default:
			return "gray";
	}
};
const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;
const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;

const CardSkeleton = () => (
	<Skeleton>
		<Card>
			<CardHeader>
				<Heading />
			</CardHeader>
			<CardBody />
			<CardFooter>
				<Button>
					<FaChevronRight />
				</Button>
			</CardFooter>
		</Card>
	</Skeleton>
);
