import {
	Heading,
	Text,
	SimpleGrid,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Skeleton,
	Flex,
	Card,
	CardHeader,
	Link as ChakraLink,
	CardBody,
	Badge,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import styled from "styled-components";
import { MenuBar, PageContainer } from "../components/atoms";
import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { User } from "../types";

const USER_QUERY = gql`
	query SelfEarnyStaffAdminAppGetUser(
		$selfEarnyStaffAdminAppGetUserId: String
	) {
		selfEarnyStaffAdminAppGetUser(id: $selfEarnyStaffAdminAppGetUserId) {
			fullName
			email
			dateOfBirth
			customerSlider
			creationDate
			availableHoursPerWeek
			accountSetupStatus
			phone {
				areaCode
				phoneNumber
			}
			contactPermissions {
				phone {
					call
					sms
				}
			}
			profile {
				color
				emoji
			}
			referral {
				referralCode
				referrer
			}
			skills
			uid
			videoUrl
		}
	}
`;

export const ViewUser = () => {
	const { state } = useLocation();
	const { id }: any = state;

	const { loading, data } = useQuery(USER_QUERY, {
		variables: { selfEarnyStaffAdminAppGetUserId: id },
	});

	useEffect(() => {
		setUser(data?.selfEarnyStaffAdminAppGetUser);
	}, [data]);

	const [user, setUser] = useState<User>();

	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/view-users">
							View users
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>{user?.fullName}</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<SpacingContainer>
					<Heading>
						<i>{user?.fullName}</i> applicants
					</Heading>
				</SpacingContainer>

				<Divider />

				<SpacingContainer>
					<Heading size="md">Applicant details</Heading>
				</SpacingContainer>

				{loading ? (
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						<Skeleton height={200} />
						<Skeleton height={200} />
						<Skeleton height={200} />
					</SimpleGrid>
				) : (
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						<Card>
							<CardHeader>
								<Heading size="md">Personal details</Heading>
							</CardHeader>
							<CardBody>
								<Flex
									alignItems="center"
									justifyContent="center"
									bgColor={user?.profile?.color}
									borderRadius="50%"
									h="50px"
									w="50px"
									marginBottom={4}
								>
									<Text fontSize="2xl" mb="0">
										{user?.profile?.emoji}
									</Text>
								</Flex>
								<Text>
									<b>Full name:</b> {user?.fullName}
								</Text>
								<Text>
									<b>Email:</b> {user?.email}
								</Text>
								<Text>
									<b>Phone:</b> {user?.phone?.areaCode}{" "}
									{user?.phone?.phoneNumber}
								</Text>
								<Text>
									<b>Phone contact permissions:</b>
									<Badge
										colorScheme={
											user?.contactPermissions?.phone.call ? "green" : "red"
										}
									>
										{user?.contactPermissions?.phone.call ? "✅" : "❌"} Call
									</Badge>
									<Badge
										colorScheme={
											user?.contactPermissions?.phone.sms ? "green" : "red"
										}
									>
										{user?.contactPermissions?.phone.sms ? "✅" : "❌"} SMS
									</Badge>
								</Text>

								<Text>
									{user?.videoUrl ? (
										<ChakraLink href={user?.videoUrl} isExternal>
											View video <FaExternalLinkSquareAlt />
										</ChakraLink>
									) : (
										<Text>No video uploaded</Text>
									)}
								</Text>
							</CardBody>
						</Card>

						<Card>
							<CardHeader>
								<Heading size="md">Referral details</Heading>
							</CardHeader>
							<CardBody>
								<Text>
									<b>User's Referral code:</b> {user?.referral?.referralCode}
								</Text>
								<Text>
									<b>Referrer:</b>{" "}
									{user?.referral?.referrer || "No referral code"}
								</Text>
							</CardBody>
						</Card>

						<Card>
							<CardHeader>
								<Heading size="md">Skills</Heading>
							</CardHeader>
							<CardBody>
								{user?.skills?.map((skill, i) => (
									<Text key={i}>{skill}</Text>
								))}
								{user?.skills?.length === 0 && (
									<Text>No skills have been added yet.</Text>
								)}
							</CardBody>

							<CardHeader>
								<Heading size="md">Account details</Heading>

								<Text>
									<b>Account setup status:</b>{" "}
									{user?.accountSetupStatus?.replaceAll("_", " ")}
								</Text>
							</CardHeader>
						</Card>
					</SimpleGrid>
				)}
			</Container>
		</PageContainer>
	);
};

const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;

const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;
