export interface User {
	profile: {
		emoji: string;
		color: string;
	};
	fullName: string;
	dateOfBirth: Date;
	email: string;
	phone: {
		areaCode: number;
		phoneNumber: number;
	};
	contactPermissions: {
		phone: {
			sms: boolean;
			call: boolean;
		};
	};
	availableHoursPerWeek: number;
	customerSlider: number;
	creationDate: Date;
	uid: string;
	referral: {
		referrer?: string;
		referralCode: string;
	};
	videoUrl: string;
	skills: string[];
	accountSetupStatus: ACCOUNT_SETUP_STATUS;
}

export enum ACCOUNT_SETUP_STATUS {
	RECORD_VIDEO = "RECORD_VIDEO",
	JOB_MATCHING = "JOB_MATCHING",
	MATCHED = "MATCHED",
	INTERVIEWING = "INTERVIEWING",
	COMPLETE = "COMPLETE",
}
