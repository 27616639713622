import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from "@chakra-ui/react";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onSignOut: () => void;
};

export const LogoutModal: React.FC<Props> = ({
	isOpen,
	onClose,
	onSignOut,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>🧐 Logout</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>Are you sure you want to log out?</Text>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="red" mr={3} onClick={onSignOut}>
						Logout
					</Button>
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
