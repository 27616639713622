import {
	Heading,
	Text,
	SimpleGrid,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Skeleton,
	Flex,
	Card,
	CardHeader,
	Link as ChakraLink,
	CardBody,
	Badge,
	Button,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import styled from "styled-components";
import { MenuBar, PageContainer } from "../components/atoms";
import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Job } from "../types";

const BUSINESS_QUERY = gql`
	query BusinessQuery($selfEarnyStaffAdminViewBusinessId: String) {
		selfEarnyStaffAdminViewBusiness(id: $selfEarnyStaffAdminViewBusinessId) {
			id
			businessUsers {
				email
				id
				accessToken
			}
			activeJobListings {
				id
				jobName
				jobDescription
				wage
				creationDate
				startDate
			}
			name
		}
	}
`;

interface ViewBusiness {
	id: string;
	name: string;
	activeJobListings: Job[];
	businessUsers: {
		email: string;
		id: string;
		accessToken: string;
	}[];
}

export const ViewBusiness = () => {
	const { state } = useLocation();
	const { id }: any = state;

	const { loading, data } = useQuery(BUSINESS_QUERY, {
		variables: { selfEarnyStaffAdminViewBusinessId: id },
	});

	useEffect(() => {
		setBusiness(data?.selfEarnyStaffAdminViewBusiness);
	}, [data]);

	const [business, setBusiness] = useState<ViewBusiness>();

	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/view-businesses">
							View businesses
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>{business?.name}</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<SpacingContainer>
					<Heading>
						<i>{business?.name}</i>
					</Heading>
				</SpacingContainer>

				<Divider />

				<SpacingContainer>
					<Heading>Business details</Heading>
				</SpacingContainer>

				{loading ? (
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						<Skeleton height={200} />
						<Skeleton height={200} />
						<Skeleton height={200} />
					</SimpleGrid>
				) : (
					<>
						{business?.businessUsers.map((user) => (
							<ChakraLink
								href={`https://dashboard.earny.io/admin/emulate?accessToken=${user.accessToken}`}
								mb={4}
							>
								<Button>
									Emulate {user.email} in Business Dashboard
									<FaExternalLinkSquareAlt />
								</Button>
							</ChakraLink>
						))}
						<Heading size="md">
							{business?.activeJobListings?.length || 0} job listings
						</Heading>
						<SimpleGrid columns={[1, 1, 3]} spacing={10}>
							{business?.activeJobListings?.map((job) => (
								<Card key={job.id}>
									<CardHeader>
										<Heading size="md">{job.jobName}</Heading>
									</CardHeader>
									<CardBody>
										<Text>
											<i>Description: </i>
											{job?.jobDescription}
										</Text>
										<Text>{job?.wage} EUR / hr</Text>
										<Text>
											<i>Created: </i>
											{new Date(
												job?.creationDate?._seconds * 1000 +
													job?.creationDate?._nanoseconds / 1000000
											).toDateString()}
										</Text>
										<Text>
											<i>Starts: </i>
											{new Date(job?.startDate).toDateString()}
										</Text>
									</CardBody>
								</Card>
							))}
						</SimpleGrid>
					</>
				)}
			</Container>
		</PageContainer>
	);
};

const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;

const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;
