import {
	Heading,
	Button,
	FormControl,
	FormLabel,
	Input,
	Container,
	Text,
	Stack,
	InputGroup,
	InputLeftElement,
	Icon,
	Box,
	Divider,
	useToast,
	Image,
	Badge,
} from "@chakra-ui/react";
import { useContext, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { AuthContextType } from "../../contexts/AuthContextType";
import { FaRegEnvelope, FaLock } from "react-icons/fa";
import { gql, useLazyQuery } from "@apollo/client";

const CHECK_SELF_QUERY = gql`
	query CheckSelf {
		selfEarnyStaffAdminCheckSignIn
	}
`;

export const SignIn = () => {
	const emailRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	const [, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const { signIn } = useContext<AuthContextType>(AuthContext);
	const [checkSelfQuery, { data }] = useLazyQuery(CHECK_SELF_QUERY);
	const navigate = useNavigate();
	const toast = useToast();

	async function handleSubmit() {
		try {
			setError("");
			setLoading(true);
			await signIn(emailRef.current!.value, passwordRef.current!.value);
			await checkSelfQuery();
			if (data?.selfEarnyStaffAdminCheckSignIn) navigate("/dashboard");
		} catch (error) {
			toast({
				title: "Login failed.",
				status: "error",
				isClosable: true,
			});
			setError((error as Error).message);
		}

		setLoading(false);
	}

	return (
		<Container
			className="d-flex align-items-center justify-content-center"
			style={{ minHeight: "100vh" }}
		>
			<Box
				width={{ base: "90%", md: "400px" }}
				bg="secondary.card"
				rounded="lg"
				p={5}
			>
				<Box pb="2">
					<Badge colorScheme="purple" mb={4}>
						Internal stuff here!
					</Badge>
					<Image src="logo.png" height="40px" width="auto" />
				</Box>

				<Heading marginBottom="1.5rem">Sign in</Heading>

				<Stack spacing={4} marginBottom="1rem">
					<FormControl>
						<FormLabel htmlFor="email">Email Address</FormLabel>
						<InputGroup>
							<InputLeftElement
								children={
									<Icon as={FaRegEnvelope} color="secondary.inputHelper" />
								}
							/>
							<Input
								focusBorderColor="purple.500"
								type="email"
								name="email"
								id="email"
								ref={emailRef}
								placeholder="name@example.com"
								required
							/>
						</InputGroup>
					</FormControl>

					<FormControl>
						<Stack justifyContent="space-between" isInline>
							<FormLabel htmlFor="password">Password</FormLabel>
							<ChakraLink
								as={ReactRouterLink}
								to="/reset-password"
								color="secondary.link"
								fontSize="sm"
								fontWeight="500"
							>
								Forgot Password?
							</ChakraLink>
						</Stack>
						<InputGroup>
							<InputLeftElement
								children={<Icon as={FaLock} color="secondary.inputHelper" />}
							/>
							<Input
								focusBorderColor="purple.500"
								name="password"
								id="password"
								type="password"
								autoComplete="current-password"
								placeholder="Enter your password"
								ref={passwordRef}
								required
							/>
						</InputGroup>
					</FormControl>
				</Stack>
				<Stack marginBottom="1rem">
					<Button
						type="submit"
						colorScheme="purple"
						isLoading={loading}
						onClick={handleSubmit}
					>
						Sign in
					</Button>
				</Stack>
			</Box>
		</Container>
	);
};
