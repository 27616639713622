import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import {
	Heading,
	Text,
	StatGroup,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	StatArrow,
	Card,
	SimpleGrid,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AuthContextType } from "../contexts/AuthContextType";
import { MenuBar, PageContainer } from "../components/atoms";
import styled from "styled-components";
import { FaArrowRight } from "react-icons/fa";
import { gql, useQuery } from "@apollo/client";

const STATS_QUERY = gql`
	query SelfEarnyStaffAdminHomePageStats {
		selfEarnyStaffAdminHomePageStats {
			jobListingCount
			newUsersLastWeek
			userCount
			businessCount
		}
	}
`;

export const Dashboard = () => {
	const { currentUser } = useContext<AuthContextType>(AuthContext);
	const { data: stats } = useQuery(STATS_QUERY);
	const { jobListingCount, newUsersLastWeek, userCount, businessCount } =
		stats?.selfEarnyStaffAdminHomePageStats || {};

	return (
		<PageContainer>
			<MenuBar />

			<Container>
				<SpacingContainer>
					<Heading>Earny Internal Overview Dashboard</Heading>
					<Heading size="md">
						Welcome{" "}
						{currentUser?.email
							?.split("@")[0]
							.replace(
								/\w\S*/g,
								(text) =>
									text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
							)}
					</Heading>
				</SpacingContainer>

				<SpacingContainer>
					<StatGroup>
						<Stat>
							<StatLabel>Total Users</StatLabel>
							<StatNumber>{userCount}</StatNumber>
							<StatHelpText>
								<StatArrow type="increase" />
								{newUsersLastWeek} last week
							</StatHelpText>
						</Stat>

						<Stat>
							<StatLabel>Total businesses</StatLabel>
							<StatNumber>{businessCount}</StatNumber>
						</Stat>

						<Stat>
							<StatLabel>Total job listings</StatLabel>
							<StatNumber>{jobListingCount}</StatNumber>
						</Stat>
					</StatGroup>
				</SpacingContainer>
				<SpacingContainer>
					<SimpleGrid
						spacing={4}
						templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
					>
						<Link to="/view-users">
							<Card variant="filled">
								<CardHeader>
									<Heading size="md">Users 👨‍👩‍👧‍👦</Heading>
								</CardHeader>
								<CardBody>
									<Text>
										Manage and view users who have signed up to the Earny app.
									</Text>
								</CardBody>
								<CardFooter>
									<Button>
										<FaArrowRight />
									</Button>
								</CardFooter>
							</Card>
						</Link>

						<Link to="/view-businesses">
							<Card variant="filled">
								<CardHeader>
									<Heading size="md">Businesses & job listings 🏢</Heading>
								</CardHeader>
								<CardBody>
									<Text>
										View businesses and their users. Create and modify job
										listings.
									</Text>
								</CardBody>
								<CardFooter>
									<Button>
										<FaArrowRight />
									</Button>
								</CardFooter>
							</Card>
						</Link>

						{/* <Link to="/manage-employees">
							<Card variant="filled">
								<CardHeader>
									<Heading size="md">Manage employees</Heading>
								</CardHeader>
								<CardBody>
									<Text>
										Modify rotas, schedule shifts and manage employees.
									</Text>
								</CardBody>
								<CardFooter>
									<Button>
										<FaArrowRight />
									</Button>
								</CardFooter>
							</Card>
						</Link> */}
					</SimpleGrid>
				</SpacingContainer>
			</Container>
		</PageContainer>
	);
};

const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;
