import React from "react";
import { Flex } from "@chakra-ui/react";

export const PageContainer = (props: { children: React.ReactNode }) => {
	return (
		<Flex
			minHeight="100%"
			width="100%"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
		>
			{props.children}
		</Flex>
	);
};
