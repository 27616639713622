import { AuthProvider, auth } from "./providers/AuthProvider";
import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	createHttpLink,
	from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
	AuthRoute,
	Dashboard,
	ErrorPage,
	Home,
	PrivateRoute,
	ResetPassword,
	Root,
	SignIn,
	ViewUsers,
	ViewUser,
	ViewBusinesses,
	ViewBusiness,
	ManageSms,
} from "./pages";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";

const removeTypenameLink = removeTypenameFromVariables();

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<Root />} errorElement={<ErrorPage />}>
			<Route index element={<Home />} />
			<Route>
				<Route element={<PrivateRoute />}>
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="view-users" element={<ViewUsers />} />
					<Route path="view-users/sms" element={<ManageSms />} />
					<Route path="view-users/view" element={<ViewUser />} />
					<Route path="view-businesses" element={<ViewBusinesses />} />
					<Route path="view-businesses/view" element={<ViewBusiness />} />
				</Route>
				<Route element={<AuthRoute />}>
					<Route path="signin" element={<SignIn />} />
					<Route path="reset-password" element={<ResetPassword />} />
				</Route>
			</Route>
		</Route>
	)
);

export default function App() {
	const httpLink = createHttpLink({
		uri: import.meta.env.DEV
			? "http://localhost:4000/graphql"
			: "https://server.earny.io/graphql",
	});

	const authLink = setContext((_, { headers }) => {
		return new Promise((resolve, reject) => {
			// get the authentication token from local storage if it exists
			// return the headers to the context so httpLink can read them
			auth.currentUser
				?.getIdToken()
				.then((token) => {
					resolve({
						headers: {
							...headers,
							Token: token,
						},
					});
				})
				.catch(() => {
					resolve({
						headers: { ...headers },
					});
				});
		});
	});

	const link = from([removeTypenameLink, authLink.concat(httpLink)]);

	const client = new ApolloClient({
		link,
		cache: new InMemoryCache(),
	});

	return (
		<AuthProvider>
			<ApolloProvider client={client}>
				<ChakraProvider>
					<RouterProvider router={router} />
				</ChakraProvider>
			</ApolloProvider>
		</AuthProvider>
	);
}
