import {
	Heading,
	Button,
	FormControl,
	FormLabel,
	Input,
	Container,
	Text,
	Stack,
	InputGroup,
	InputLeftElement,
	Icon,
	Box,
	Divider,
	useToast,
	Image,
} from "@chakra-ui/react";
import { useContext, useRef, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Link, Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { AuthContextType } from "../contexts/AuthContextType";
import { FaRegEnvelope, FaLock } from "react-icons/fa";

export const ErrorPage = () => {
	const navigate = useNavigate();
	const toast = useToast();

	return (
		<Container
			className="d-flex align-items-center justify-content-center"
			style={{ minHeight: "100vh" }}
		>
			<Box
				width={{ base: "90%", md: "400px" }}
				bg="secondary.card"
				rounded="lg"
				p={5}
			>
				<Heading marginBottom="1.5rem">Ouch, you got lost...</Heading>
				<Heading size="sm" marginBottom="1rem">
					... let's get you back.
				</Heading>

				<Divider marginBottom="1rem" />
				<Stack marginBottom="1rem">
					<Button as={Link} colorScheme="purple" to="/dashboard">
						Takeee me home! 🏠
					</Button>
				</Stack>
			</Box>
		</Container>
	);
};
